<template>
  <!-- Footer start -->
  <footer class="h-full w-full relative  py-10 lg:py-20  px-4 bg-[#090B2A]">
    <div class="flex flex-col   lg:px-10 xl:px-20 max-w-[1400px] mx-auto">
      <!-- Main Row: Logo, Navigation Links, Social Icons -->
      <div class="flex flex-col sm:flex-row justify-between items-center w-full gap-6">
        <!-- Logo Section -->
        <div class="flex items-center gap-2">
          <!-- <a @click="showMenu = false; this.isScrolled = false;" href="">
            <img v-motion-pop-visible alt="logo" src="@/assets/nav-logo.png" />
          </a> -->
          <a href="#home"><img v-motion-pop-visible src="@/assets/nav-logo.png" class="w-auto h-[52px] object-contain" alt="logo"></a>
        </div>

        <!-- Navigation Links -->
        <div v-motion-pop-visible
          class="flex flex-wrap justify-center gap-4 uppercase text-lg text-white lg:flex-nowrap font-extrabold font-lato">
          <a v-for="item in navItems" :key="item.id" :href="`#${item.id}`" :class="[
            'block w-full text-center px-4 py-2  transition-colors lg:inline-block lg:w-auto',
            {
              'font-extrabold text-[#69DFFF]': activeSection === item.id,
              'text-white/80 hover:text-white': activeSection !== item.id

            }
          ]">
            {{ item.name }}
          </a>

        </div>

        <!-- Social Icons + Copyright -->
        <div class="flex flex-col items-center gap-0">
          <!-- Empty space with consistent height -->
          <p class="font-bold text-sm h-4 font-oxanium" style="visibility: hidden;">
            &nbsp; <!-- Non-breaking space to ensure it renders -->
          </p>

          <!-- Icons Row -->
          <div v-motion-pop-visible class="flex flex-row items-center justify-center gap-1.5">
            <a href="https://kick.com/ramblegamble" target="_blank">
              <img src="@/assets/kick-footer.png" class="h-[25px] w-[25px]" />
            </a>
            <a href="https://www.youtube.com/channel/UC0-HvIu5bB51EppNbzAShaQ" target="_blank">
              <img src="@/assets/youtube-footer.png" class="h-[34px] w-[34px]" />
            </a>
            <a href="https://x.com/ramblegamble777" target="_blank">
              <img src="@/assets/x-footer.png" class="h-[34px] w-[34px]" />
            </a>
            <a href="https://discord.com/invite/ramble" target="_blank">
              <img src="@/assets/discord.png" class="h-[25px] w-[25px] object-contain" />
            </a>
          </div>

          <!-- Copyright Text -->
          <p class="font-bold text-sm font-oxanium text-white">
            © All rights reserved.
          </p>
        </div>

      </div>

      <!-- Centered Paragraphs Section -->
      <div class="mt-4 py-4 flex flex-col items-center gap-4 text-base font-normal text-center w-full font-lato">
        <p class="text-white">
          If you ever feel that gambling is becoming a problem, we urge you to
          seek help and support. Most people gamble for fun and enjoyment.
          However, some people think of gambling as a way to make money, spend
          more than they can afford, or use gambling to distract themselves from
          everyday problems.
        </p>
        <p class="text-[#BBBBBBBB] font-bold font-oxanium">
          To find further advice, help and support visit:
          <span class="text-inherit underline"> <a href=" https://www.begambleaware.org/" target="_blank">
              https://www.begambleaware.org/
            </a></span>
        </p>
        <p class="text-white">
          We do not take responsibility for misunderstood promotions, losses
          from gambling in casinos or betting on sites which are linked to or
          promoted on this website. Make sure it is legal for you to use each
          site inside your jurisdiction and ALWAYS gamble responsibly!
        </p>
      </div>
    </div>
  </footer>
  <!-- Footer end -->
</template>


<script>
export default {
  name: 'FooterElement',
  props: {
    activeSection: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      navItems: [
        { id: 'home', name: 'Home' },
        { id: 'leaderboards', name: 'Leaderboards' },
        { id: 'socials', name: 'Socials' },
      ]
    };
  },
}
</script>