<template>
    <div class="pt-[88px] pb-[171px] md:pt-[141px] md:pb-[158px] bg-gradient-to-r from-[rgba(9,_11,_42,_1)] via-[#090B2A] to-[rgba(9,_11,_42,_1)]">
        <div class="text-[32px] text-white leading-10 font-black text-center font-montserrat">
          REWARDS AND PROMOS
        </div>
        <section id="promotion" class="flex justify-center items-center px-4 md:px-20 relative mt-8">
            <!-- Conditional Layouts for Mobile and Desktop -->
            <div v-motion-pop-visible v-if="isMobile" class="relative w-full h-auto max-w-[400px]">
                <img :src="currentSlide.bgImageMobile" class="w-full h-full object-cover object-center"
                    alt="background-image" />
                <div
                    class="absolute inset-0 flex flex-col items-center justify-center text-center text-white space-y-4 font-montserrat p-4 z-10">
                    <p class="font-black text-[24px] uppercase">{{ currentSlide.title }}</p>
                    <img :src="currentSlide.logo" alt="promo-logo" class="w-32" />
                    <ul class="list-disc list-inside text-[18px] space-y-2 uppercase">
                        <li v-for="(bullet, i) in currentSlide.bullets" :key="i">{{ bullet }}</li>
                    </ul>
                    <p v-if="currentIndex === 0" class="pt-2 flex items-center font-black uppercase text-[14px] space-x-2">
                        <img :src="currentSlide.infoIcon" alt="info icon" class="w-4 h-4" />
                        <span>{{ currentSlide.residencyNote }}</span>
                    </p>
                    <PromoButton :link="currentSlide.buttonLink" :text="currentSlide.buttonText"
                        :btnStyleLeft="currentSlide.btnStyleLeft" :btnStyleRight="currentSlide.btnStyleRight" />
                </div>
                <div class="absolute inset-x-0 -bottom-8 flex justify-center space-x-3 z-20">
                    <button v-for="(slide, index) in slides" :key="index" @click="setCurrentSlide(index)"
                        :class="indicatorClass(index)"
                        class="w-4 h-4 rounded-full focus:outline-none transition-all duration-300">
                    </button>
                </div>
            </div>

            <!-- Carousel Layout for Desktop -->
            <div v-motion-pop-visible v-else class="relative w-full h-auto max-w-[1265px] md:max-h-[430px]">
                <img :src="currentSlide.bgImage" class="-z-10 w-full h-full object-cover object-center"
                    alt="background-image" />
                <div class="absolute inset-0 flex justify-center items-center z-10">
                    <div :class="currentIndex === 0 ? '' : 'pl-[400px]'"
                        class="carousel-slide-content w-full h-full flex flex-col items-start justify-start text-left text-white space-y-4 font-montserrat text-[18px]">
                        <div class="flex flex-nowrap sm:flex-row flex-col justify-center items-center gap-8">
                            <p class="font-black text-[48px] uppercase">{{ currentSlide.title }}</p>
                            <img :src="currentSlide.logo" alt="promo-logo" />
                        </div>
                        <ul class="list-disc list-inside space-y-2 ">
                            <li v-for="(bullet, i) in currentSlide.bullets" :key="i">{{ bullet }}</li>
                        </ul>
                        <p v-if="currentIndex === 0" class="pt-2 flex items-center font-black uppercase space-x-2">
                            <img :src="currentSlide.infoIcon" alt="info icon" class="w-6 h-6" />
                            <span>{{ currentSlide.residencyNote }}</span>
                        </p>
                        <PromoButton :class="currentIndex !== 0 ? 'self-end ' : ''" :link="currentSlide.buttonLink"
                            :text="currentSlide.buttonText" :btnStyleLeft="currentSlide.btnStyleLeft"
                            :btnStyleRight="currentSlide.btnStyleRight" />
                    </div>
                </div>
                <div class="absolute inset-x-0 -bottom-8 flex justify-center space-x-3 z-20">
                    <button v-for="(slide, index) in slides" :key="index" @click="setCurrentSlide(index)"
                        :class="indicatorClass(index)"
                        class="w-4 h-4 rounded-full focus:outline-none transition-all duration-300">
                    </button>
                </div>
            </div>

        </section>
    </div>
</template>

<script>
import PromoButton from '@/components/PromoButton.vue';

export default {
    name: "PromotionCarousel",
    components: {
        PromoButton
    },
    data() {
        return {
            currentIndex: 0,
            slides: [
                {
                    title: "REGISTER ON",
                    logo: new URL('@/assets/promo-stake-us.png', import.meta.url).href,
                    bgImage: new URL('@/assets/promo-girl-bg.png', import.meta.url).href,
                    bgImageMobile: new URL('@/assets/promo-stake-sm.png', import.meta.url).href,
                    bullets: [
                        "Get a Free $25 Bonus when you sign up and verify your account",
                        "Plus enjoy $1 in free stake cash every day!"
                    ],
                    infoIcon: new URL('@/assets/promo-icon-info.png', import.meta.url).href,
                    residencyNote: "Exclusively for USA residents.",
                    buttonLink: "https://stake.us/?offer=ramble&c=ramble",
                    buttonText: "REGISTER ON STAKE.US",
                    btnStyleLeft: new URL('@/assets/hero-btn-style-1.png', import.meta.url).href,
                    btnStyleRight: new URL('@/assets/hero-btn-style-2.png', import.meta.url).href,
                },
                {
                    title: "REGISTER ON",
                    logo: new URL('@/assets/promo-stake-com.png', import.meta.url).href,
                    bgImage: new URL('@/assets/promo-boy-bg.png', import.meta.url).href,
                    bgImageMobile: new URL('@/assets/promo-stake-sm.png', import.meta.url).href,
                    bullets: [
                        "Unlock a 200% bonus on your first deposit!",
                        "Earn 100% of affiliate earnings back as player rewards.",
                        "Stay active for a chance to top the Monthly Leaderboards and receive random bonus tips!"
                    ],
                    buttonLink: "https://stake.com/?offer=ramble&c=3539bdefe4",
                    buttonText: "REGISTER ON STAKE.COM",
                    btnStyleLeft: new URL('@/assets/hero-btn-style-1.png', import.meta.url).href,
                    btnStyleRight: new URL('@/assets/hero-btn-style-2.png', import.meta.url).href,
                },
            ],
            interval: null,
        };
    },
    computed: {
        isMobile() {
            return window.innerWidth < 640;
        },
        currentSlide() {
            return this.slides[this.currentIndex];
        }
    },
    mounted() {
        this.startAutoPlay();
        window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
        this.stopAutoPlay();
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        setCurrentSlide(index) {
            this.currentIndex = index;
            this.restartAutoPlay();
        },
        nextSlide() {
            this.currentIndex = (this.currentIndex + 1) % this.slides.length;
        },
        indicatorClass(index) {
            return this.currentIndex === index ? 'bg-[#3E6B3E] px-[30px]' : 'bg-white';
        },
        startAutoPlay() {
            this.interval = setInterval(this.nextSlide, 10000);
        },
        stopAutoPlay() {
            clearInterval(this.interval);
        },
        restartAutoPlay() {
            this.stopAutoPlay();
            this.startAutoPlay();
        },
        handleResize() {
            console.log(window.innerWidth, 'resize');
            this.$forceUpdate();
        }
    },
};
</script>

<style scoped>
.carousel-slide-content {
    max-width: 95%;
    max-height: 95%;
}
</style>
