<template>
  <div class="relative">
    <section class="h-full max-h-[1024px] relative bg-gradient-to-r from-[rgba(9,_11,_42,_1)] via-[#090B2A] to-[rgba(9,_11,_42,_1)]" id="home">
      <!-- Background Image -->
      <!-- class="-z-10 absolute inset-0 w-full h-full object-cover bg-center bg-repeat-y"  -->
      <img src="@/assets/hero-bg.png"
        class="z-10 w-full h-full max-h-[1024px] absolute inset-0 object-cover object-center bg-repeat-y" alt="background-image" />

      <!-- Content Container -->
      <div
        class="flex flex-col pt-20 md:pt-40 p-8 lg:pt-24 2xl:pt-44 lg:px-16 2xl:px-56 z-30  justify-center items-center">
        <!-- Hero Image (Responsive Display) -->
        <div class="flex justify-center">
          <!-- Mobile Image -->
          <img v-motion-pop-visible src="@/assets/hero-sm.png"
            class="z-20 w-[270px] sm:w-[400px] md:w-[500px] block lg:hidden mt-10" alt="mobile-title-text" />

          <!-- Desktop Image -->
          <img v-motion-pop-visible src="@/assets/hero-ramble-gamble.png" class="z-20 hidden lg:block lg:w-full 2xl:w-full"
            alt="desktop-title-text" />
        </div>

        <!-- 'WITH' Text -->
        <div class="py-4 text-white font-['Lato'] font-black text-xl sm:text-[36px] z-20">WITH</div>

        <!-- Stake Image -->
        <div class="flex justify-center mt-0">
          <img v-motion-pop-visible src="@/assets/hero-stake.png" class="z-20 block lg:hidden max-h-[100px] sm:max-h-[206px]"
            alt="title-stake" />
          <img v-motion-pop-visible src="@/assets/hero-stake.png" class="z-20 hidden lg:block max-h-[200px] sm:max-h-[206px]"
            alt="title-stake" />
        </div>

        <!-- Buttons Section -->
        <div v-motion-pop-visible
          class="flex flex-nowrap sm:flex-row flex-col justify-center items-center gap-4 lg:gap-12 mt-4 z-20 mb-[86px] md:mb-[122px]">
          <div class="relative h-[72px] w-[300px] rounded-lg bg-[#3E6B3E] 
            hover:bg-gradient-to-b from-[#ED3C80] to-[#3E6B3E] 
            hover:shadow-[0_0_8px_4px_#3E6B3E] transition-shadow duration-200">
            <div class="absolute inset-0 m-[2px] rounded-lg bg-[#3E6B3E] 
              overflow-hidden flex items-center justify-center">
              <a href="https://stake.us/?offer=ramble&c=ramble" class="z-10 px-14 py-6 text-md font-bold text-[#090B2A] 
              text-center whitespace-nowrap" style="font-family: Lato, sans-serif;">
                REGISTER ON STAKE.US
              </a>
              <img src="@/assets/hero-btn-style-1.png" alt="Decorative slant shadow"
                class="absolute inset-y-0 left-0 h-full pointer-events-none" />
              <img src="@/assets/hero-btn-style-2.png" alt="Decorative slant shadow"
                class="absolute inset-y-0 right-0 h-full pointer-events-none" />
            </div>
          </div>

          <div class="relative h-[72px] w-[300px] rounded-lg 
            bg-gradient-to-b from-[#ED3C80] to-[#3E6B3E] 
            hover:shadow-[0_0_8px_4px_#3E6B3E] transition-shadow duration-200">
            <div class="absolute inset-0 m-[2px] rounded-lg bg-[#090B2A] 
              overflow-hidden flex items-center justify-center">
              <a href="https://stake.com/?offer=ramble&c=3539bdefe4" class="z-10 px-14 py-6 text-md font-bold text-white 
              text-center whitespace-nowrap" style="font-family: Lato, sans-serif;">
                REGISTER ON STAKE.COM
              </a>
              <img src="@/assets/hero-btn-style-3.png" alt="Decorative slant shadow"
                class="absolute inset-y-0 left-0 h-full pointer-events-none" />
              <img src="@/assets/hero-btn-style-4.png" alt="Decorative slant shadow"
                class="absolute inset-y-0 right-0 h-full pointer-events-none" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <PromotionSection />
    <LeaderboardSection />
    <SocialSection />
  </div>
</template>

<script>
import LeaderboardSection from "@/sections/LeaderboardSection.vue";
import PromotionSection from "@/sections/PromotionSection.vue";
import SocialSection from "@/sections/SocialSection.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    PromotionSection,
    LeaderboardSection,
    SocialSection,
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    this.$emit("sections-ready");
  },
});
</script>



<style scope>
#promo-card {
  background-image: url("../assets/promo-card.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
