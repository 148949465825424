<template>
    <div class="relative">
        <!-- Navbar -->
        <nav :class="{ 'backdrop-blur-md bg-opacity-95': isScrolled, 'bg-[#090B2A]': isSidebarOpen }"
            class="fixed top-0 left-0 w-full px-4 py-3.5  transition-all duration-300 ease-in-out z-40">
            <div class="container  lg:px-10 xl:px-20 mx-auto max-w-[1400px] flex justify-between items-center">
                <!-- Logo -->
                <a href="#home"><img v-motion-pop-visible src="@/assets/nav-logo.png" class="w-auto h-[52px] object-contain" alt="logo"></a>
                <!-- Desktop Navigation -->
                <div v-motion-pop-visible
                    class="hidden md:flex flex-col lg:flex-row justify-evenly gap-4 uppercase  font-extrabold font-lato text-lg whitespace-nowrap">
                    <a v-for="item in navItems" :key="item.id" :href="`#${item.id}`" :class="[
                        'block w-full lg:w-auto text-[18px] px-4 py-2 rounded transition-all duration-300 ease-in-out',
                        {
                            'font-extrabold text-[#69DFFF]': activeSection === item.id,
                            'text-white/80 hover:text-white': activeSection !== item.id
                        }
                    ]">
                        {{ item.name }}
                    </a>
                </div>

                <!-- Mobile Hamburger Button -->
                <div class="md:hidden">
                    <button @click="toggleSidebar" class="focus:outline-none">
                        <svg v-if="!isSidebarOpen" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"
                            class="w-9 h-9">
                            <path fill-rule="evenodd"
                                d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z"
                                clip-rule="evenodd" />
                        </svg>
                        <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke-width="1.5" stroke="white" class="w-9 h-9">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
            </div>
        </nav>
        <!-- Mobile Sidebar Drawer -->
        <div :class="{ 'translate-x-0 w-[85%]': isSidebarOpen, '-translate-x-full': !isSidebarOpen }"
            class="fixed  top-[0] left-0 h-full bg-[#090B2A] transform transition-transform duration-300 ease-in-out z-30">
            <div class="mt-[85px] text-white font-extrabold font-lato text-lg uppercase">
                <a v-for="item in navItems" :key="item.id" :href="`#${item.id}`" @click="toggleSidebar"
                    class="block w-full text-left px-6 py-4 mb-1 transition duration-200 "
                    :class="{ 'bg-[#69DFFF1d] text-[#69DFFF]': activeSection === item.id }">
                    {{ item.name }}
                </a>
            </div>
        </div>

        <!-- Sidebar Overlay -->
        <div v-if="isSidebarOpen" @click="closeSidebar"
            class="fixed inset-0 bg-gray-950 bg-opacity-75 z-20 transition-opacity duration-300 ease-in-out">
        </div>

    </div>
</template>

<script>
export default {
    name: 'NavbarElement',
    props: {
        activeSection: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isScrolled: false,
            isSidebarOpen: false,
            navItems: [
                { id: 'home', name: 'Home' },
                { id: 'leaderboards', name: 'Leaderboards' },
                { id: 'socials', name: 'Socials' },
            ]
        };
    },
    methods: {
        toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;

            if (this.isSidebarOpen && window.innerWidth < 768) {
                this.isScrolled = true;
            } else if (!this.isSidebarOpen && window.scrollY === 0) {
                this.isScrolled = false;
            }
        },
        closeSidebar() {
            this.isSidebarOpen = false;
        },
        handleScroll() {
            this.isScrolled = window.scrollY > 0;

            if (this.isScrolled && window.innerWidth < 768) {
                this.isScrolled = true;
            }
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>