<template>
  <section id="socials" class="flex flex-col justify-center items-center py-10 md:py-16 lg:py-20 bg-[#0F212E]"
    style="padding-top: 200px; padding-bottom: 200px;">
    <div class="flex items-center justify-center w-full">
      <div v-motion-slide-visible-bottom class="flex flex-col items-center gap-6">
        <!-- Text Row -->
        <div class="text-[32px] font-black text-center pb-6 md:pb-8 lg:pb-10 font-montserrat">
          <span class="text-[#69DFFF]">FOLLOW ME </span>
          <span class="text-white">ON MY SOCIALS</span>
        </div>


        <!-- Icons Container -->
        <div class="grid grid-cols-2 gap-4 md:gap-[74px] md:flex md:flex-wrap md:justify-center">
          <!-- Icon Item Template -->
          <div v-for="(icon, index) in socialIcons" :key="index" class="overflow-hidden relative w-[145px] h-[145px] rounded-lg 
            bg-gradient-to-b from-[#ED3C80] to-[#4FC380] 
            hover:scale-105 transition-shadow duration-200 ">
            <div class="absolute inset-0 m-[2px] rounded-lg bg-[#0F212E]
              overflow-hidden flex items-center justify-center">
              <a :href="icon.url" target="_blank">
                <img :src="icon.img" class="h-10 w-10 md:h-12 md:w-12 object-contain" :alt="icon.alt" />
              </a>
            </div>
          </div>

          <!-- <div v-for="(icon, index) in socialIcons" :key="index"
            class="rounded-lg transform transition-transform duration-200 hover:scale-105"
            style="background: linear-gradient(#ED3C80, #4FC380); padding: 1px;">
            <div class="bg-[#0F212E] rounded-lg p-4 md:p-6">
              <div class="rounded-lg p-4 md:p-6 flex items-center justify-center">
                <a :href="icon.url" target="_blank">
                  <img :src="icon.img" class="h-10 w-10 md:h-12 md:w-12" :alt="icon.alt" />
                </a>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      socialIcons: [
        {
          url: 'https://www.youtube.com/channel/UC0-HvIu5bB51EppNbzAShaQ',
          img: require('@/assets/youtube.png'),
          alt: 'Youtube',
        },
        {
          url: 'https://x.com/ramblegamble777',
          img: require('@/assets/X.png'),
          alt: 'X',
        },
        {
          url: 'https://discord.com/invite/ramble',
          img: require('@/assets/discord.png'),
          alt: 'Discord',
        },
        {
          url: 'https://kick.com/ramblegamble',
          img: require('@/assets/kick.png'),
          alt: 'Kick',
        },
      ],
    };
  },
};
</script>
