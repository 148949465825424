<template>
    <div
      class="relative h-[72px] w-[300px] rounded-lg bg-[#3E6B3E]
        hover:bg-gradient-to-b from-[#ED3C80] to-[#3E6B3E]
        hover:shadow-[0_0_8px_4px_#3E6B3E] transition-shadow duration-200"
    >
      <div
        class="absolute inset-0 m-[2px] rounded-lg bg-[#3E6B3E]
          overflow-hidden flex items-center justify-center"
      >
        <a
          :href="link"
          class="z-10 px-14 py-6 text-md font-bold text-[#090B2A] 
          text-center whitespace-nowrap"
          style="font-family: Lato, sans-serif;"
        >
          {{ text }}
        </a>
        <img
          :src="btnStyleLeft"
          alt="Decorative slant shadow"
          class="absolute inset-y-0 left-0 h-full pointer-events-none"
        />
        <img
          :src="btnStyleRight"
          alt="Decorative slant shadow"
          class="absolute inset-y-0 right-0 h-full pointer-events-none"
        />
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "PromoButton",
    props: {
      link: {
        type: String,
        required: true,
      },
      text: {
        type: String,
        required: true,
      },
      btnStyleLeft: {
        type: String,
        required: true,
      },
      btnStyleRight: {
        type: String,
        required: true,
      },
    },
  };
  </script>
